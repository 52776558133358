import React, { useEffect } from 'react';
import Logo from './../assets/images/ustore.png';
import { resetStores } from '@datorama/akita';
import { removeLocalStorageItem } from "../state/utils";
import { I18nextContext } from "gatsby-plugin-react-i18next";

const LogoutPage: React.FC = () => {
  const { language } = React.useContext(I18nextContext);

  useEffect(() => {
    removeLocalStorageItem('uStoreAccessToken');
    removeLocalStorageItem('uStoreAccessRedirectBackTo');
    removeLocalStorageItem('uStoreAnonCartId');
    removeLocalStorageItem('uStoreCheckoutAddressId');
    removeLocalStorageItem('uStoreCheckoutCardId');
    removeLocalStorageItem('uStoreLSTime');

    resetStores();
    setTimeout(() => {
      window.location.href = '/';
    }, 1000);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div><img src={Logo} alt="ustore" className="animate-breathing" /></div>
      <p className="text-center text-sm">{ language === 'en' ? 'please wait' : '请稍等' }</p>
    </div>
  );
};

export default LogoutPage;
